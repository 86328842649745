<template>
  <van-tabbar route active-color="#35a0b1">
    <van-tabbar-item icon="apps-o" to="/slope/xinbaiyanshi/home">首页</van-tabbar-item>
    <van-tabbar-item icon="aim" to="/slope/xinbaiyanshi/realtime">实时</van-tabbar-item>
    <van-tabbar-item icon="bullhorn-o" to="/slope/xinbaiyanshi/warning" :badge="parent ? parent : total">告警</van-tabbar-item>
    <van-tabbar-item icon="notes-o" to="/slope/xinbaiyanshi/report">报告</van-tabbar-item>
    <van-tabbar-item icon="setting-o" to="/slope/xinbaiyanshi/about">关于</van-tabbar-item>
  </van-tabbar>
</template>

<script>
export default {
  name: 'tabber',
  props: {
    parent: String
  },
  data(){
    return{
      total:window.sessionStorage.getItem('onceAlarm') != '0' ? window.sessionStorage.getItem('onceAlarm') : ''
    }
  },
  mounted(){
    !window.sessionStorage.getItem('onceAlarm') ?  this.alarmNum() : void 0 ;
  },
  methods:{
    alarmNum(){
      let code = window.sessionStorage.getItem('bridgeCode');
      this.$api.SLOPE.getAlarmNum({briCode:code}).then(d=>{
          d == '0' ? this.total = '' : this.total = d;
          window.sessionStorage.setItem('onceAlarm',d);
      })
    }
  }
}
</script>
